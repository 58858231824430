import React from 'react';
import styled from 'styled-components';

import type { BaseSelectRef } from 'rc-select';
import { Typography, Flex, Select as SelectAnt } from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import { type SelectProps } from 'antd/lib';
import { ErrorText } from '../Inputs/Input/ErrorText/ErrorText';

const { Text } = Typography;

const Container = styled(Flex)`
  height: 72px;
  justify-content: space-between;
`;

interface IProps {
  title: string;
  defaultValue?: string;
  value?: string | DefaultOptionType;
  options: DefaultOptionType[];
  testId?: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  labelInValue?: boolean;
  errorText?: string;
  selectRef?: React.Ref<BaseSelectRef>,
  onChange?: SelectProps['onChange'];
  optionRender?: SelectProps['optionRender'],
  dropdownRender: SelectProps['dropdownRender'],
}

export const Select: React.FC<IProps> = ({
  title,
  defaultValue,
  value,
  options,
  testId,
  placeholder,
  loading,
  disabled,
  labelInValue,
  errorText,
  onChange,
  dropdownRender,
  selectRef,
  optionRender
}) => {
  return (
    <Container data-testid={testId} vertical>
      <Text type="secondary">{title}</Text>
      <SelectAnt
        size="large"
        defaultValue={defaultValue}
        value={value}
        loading={loading}
        style={{ width: '100%' }}
        onChange={onChange}
        options={options}
        disabled={disabled}
        labelInValue={labelInValue}
        placeholder={placeholder}
        status={errorText ? 'error' : ''}
        dropdownRender={dropdownRender}
        ref={selectRef}
        optionRender={optionRender}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </Container>
  );
};
