const VACATION_TYPE_KEYS = {
  ANNUAL: 'ANNUAL',
  HARMFUL_CONDITIONS: 'HARMFUL_CONDITIONS'
};

const VACATION_GROUP_TYPES_NAMES = {
  FAVORITE: '',
  SOCIAL: 'Соціальні відпустки',
  WITHOUT_SALARY: 'Відпустки без збереження заробітної плати'
}

export const VACATION_GROUP_TYPES_NUMBERS = {
  FAVORITE: 1,
  SOCIAL: 2,
  WITHOUT_SALARY: 3
}

export const VACATION_GROUP_TYPES: Record<number, string> = {
  [VACATION_GROUP_TYPES_NUMBERS.FAVORITE]: VACATION_GROUP_TYPES_NAMES.FAVORITE,
  [VACATION_GROUP_TYPES_NUMBERS.SOCIAL]: VACATION_GROUP_TYPES_NAMES.SOCIAL,
  [VACATION_GROUP_TYPES_NUMBERS.WITHOUT_SALARY]: VACATION_GROUP_TYPES_NAMES.WITHOUT_SALARY
}

export const VACATION_TYPES = {
  [VACATION_TYPE_KEYS.ANNUAL]: {
    name: 'Відпустка щорічна основна',
    identifier: '14d57070-051e-48bf-b93c-35baa5a4fb64'
  }
};

export const ANNUAL_VACATION_TYPE = VACATION_TYPES[VACATION_TYPE_KEYS.ANNUAL];
