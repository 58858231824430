import { Typography, type TabsProps } from 'antd';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { ROUTES } from 'src/constants/routes';
import { InfoForm, LeaveApplication, useStepperForm } from 'src/features/leaveApplication';
import { History } from 'src/features/leaveApplication/components/History';
import { LeaveApplicationTabs } from 'src/features/leaveApplications';
import { useAppDispatch } from 'src/hooks';
import {
  cleanUpCurrentLeaveApplication,
  existentPartTimeJobsLeaveApplicationsSelector,
  currentLeaveApplicationHistorySelector,
  currentLeaveApplicationSelector,
  getLeaveApplicationHistory,
  getLeaveApplicationTypesList,
  leaveApplicationTypesIsLoadingSelector,
  leaveApplicationTypesSelector,
  getLeaveApplication,
  reserveVacationSummarySelector
} from 'src/store/leaveApplications';
import { partTimeJobProfilesSelector, profileSelector } from 'src/store/sessions';
import { STEPPER_MODE } from 'src/types/leaveApplications/types';
import { type IProfile } from 'src/types/user/types';

const { Container, Content, Footer } = LeaveApplication;

const Section = styled.section`
  padding: 0 28px;
`;

export const ViewLeave: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const profile = useSelector(profileSelector) as IProfile;
  const partTimeJobProfiles = useSelector(partTimeJobProfilesSelector);
  const existentPartTimeJobsLeaveApplications = useSelector(existentPartTimeJobsLeaveApplicationsSelector);
  const currentLeaveApplication = useSelector(currentLeaveApplicationSelector);
  const currentLeaveApplicationHistory = useSelector(currentLeaveApplicationHistorySelector);
  const leaveApplicationTypes = useSelector(leaveApplicationTypesSelector);
  const reserveVacationSummary = useSelector(reserveVacationSummarySelector);
  const leaveApplicationTypesIsLoading = useSelector(leaveApplicationTypesIsLoadingSelector);

  const partTimeJobsItems = useMemo(() => {
    const items = currentLeaveApplication.partTimeJobs.items.map((item) => {
      const partTimeJobsLeaveApplications = existentPartTimeJobsLeaveApplications.find(
        application => application.companyIdentifier === item.companyIdentifier
      );

      return {
        ...item,
        isActive: !!partTimeJobsLeaveApplications,
        disabled: true
      }
    })

    return { items };
  }, [currentLeaveApplication.partTimeJobs.items]);

  const { formData, changeFormData } = useStepperForm({
    profile,
    mode: STEPPER_MODE.VIEW,
    partTimeJobs: partTimeJobsItems,
    leaveApplication: currentLeaveApplication.data,
    leaveApplicationTypes
  });

  useEffect(() => {
    dispatch(getLeaveApplicationTypesList());

    if (params.id) {
      dispatch(getLeaveApplication(Number(params.id)));
      dispatch(getLeaveApplicationHistory(Number(params.id)));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch(cleanUpCurrentLeaveApplication());
    };
  }, [dispatch]);

  const handleBack = () => {
    navigate(ROUTES.LEAVE_APPLICATIONS);
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'leaveApplication',
      label: 'Перегляд заявки',
      children: (
        <>
          <Content>
            <Section>
              <Typography.Title level={5} style={{ margin: '15px 0px' }}>
                Заявка № {currentLeaveApplication.data?.vacationId}
              </Typography.Title>
            </Section>
            <InfoForm
              disabled
              data={formData.info}
              errors={{}}
              partTimeJobProfiles={partTimeJobProfiles}
              leaveApplicationTypes={leaveApplicationTypes}
              reserveVacationSummary={reserveVacationSummary}
              leaveApplicationTypesIsLoading={leaveApplicationTypesIsLoading}
              onChange={changeFormData('info')}
            />
          </Content>
          <Footer
            cancelText={'Назад'}
            onCancel={handleBack}
            disabled={currentLeaveApplication.isLoading || leaveApplicationTypesIsLoading}
          />
        </>
      )
    },
    {
      key: 'history',
      label: 'Історія',
      children: <History data={currentLeaveApplicationHistory} />
    }
  ];

  return (
    <Container>
      <LeaveApplicationTabs tabs={tabs} defaultKey="leaveApplication" />
    </Container>
  );
};
