import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body,
  #root {
    margin: 0;
    width: 100%;
    font-size: 12px;
    background-image: linear-gradient(to right bottom, #6eb7cd, #72b4d2, #79b0d6, #83acd7, #8ea8d7, #a2a6d5, #b4a5d0, #c2a4c9, #d2a9c0, #dab0b9, #dcb9b7, #dbc3ba);
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;

    * {
      box-sizing: border-box;
    }
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-select-selector:hover,
  .ant-picker:hover {
    border-color: ${(props) => props.theme.colors.borderHover} !important;
  }

  .ant-modal-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
  }

  .ant-notification {
    z-index: 9999;
  }

  .ant-notification-notice-message {
    font-size: 20px !important;
  }

  .ant-notification-notice-description {
    font-size: 18px !important;
  }

  .ant-select-item-option-content {
    white-space: normal !important;
  }

  .ant-select-dropdown {
    z-index: 1061;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export default GlobalStyle;
