import { type ThemeConfig } from 'antd';
import { LeaveApplicationStatus } from 'src/constants/status';

export enum Colors {
  primary = '#0f3d5c',
  secondaryText = '#848A96',
  disabled = '#C5C8CF',
  disabledText = '#ffffff',
  primaryBackground = '#ffffff',
  borderColor = '#C5C8CF',
  buttonHover = '#007EFF',
  borderHover = '#007EFF',
  selectHover = 'rgba(0, 0, 0, 0.04)',
  helpTextColor = '#FFF7DB',
  switchColor = '#04C65D',
  bgChecked = '#E5E6EA',
  warning = '#FFE58F',
  warningLight = '#FFFBE6',
  danger = '#E02B1D'
}

export const defaultTheme: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    colorPrimary: Colors.primary
  },
  components: {
    Button: {
      primaryColor: Colors.primaryBackground,
      defaultColor: Colors.primary,
      colorPrimaryHover: Colors.buttonHover,
      defaultBorderColor: Colors.primary,
      colorPrimaryBorder: Colors.primary,
      borderRadius: 20,
      borderRadiusLG: 25,
      controlHeightLG: 48,
      primaryShadow: 'none',
      colorBgContainerDisabled: Colors.disabled,
      colorTextDisabled: Colors.disabledText,
      fontWeight: 600
    },
    Table: {
      headerBg: Colors.primaryBackground,
      headerColor: Colors.secondaryText,
      rowSelectedBg: Colors.disabled,
      rowSelectedHoverBg: Colors.disabled
    },
    Input: {
      borderRadiusLG: 8,
      paddingLG: 8,
      colorBorder: Colors.borderColor,
      lineWidth: 2,
      activeShadow: 'none'
    },
    Typography: {
      colorTextDescription: Colors.secondaryText,
      colorLink: Colors.primary
    },
    Select: {
      borderRadiusLG: 8,
      lineWidth: 2,
      optionSelectedBg: Colors.disabled,
      controlOutlineWidth: 0,
      colorError: Colors.danger
    },
    DatePicker: {
      borderRadius: 8,
      lineWidth: 2,
      activeShadow: 'none',
      colorBorder: Colors.borderColor
    },
    Switch: {
      colorPrimary: Colors.switchColor,
      colorPrimaryHover: Colors.switchColor
    },
    Drawer: {
      zIndexPopup: 1070
    },
    Modal: {
      zIndexPopupBase: 9998
    }
  }
};

export const LeaveApplicationStatusColors = {
  [LeaveApplicationStatus.default]: {
    bgColor: 'red',
    textColor: 'red'
  },
  [LeaveApplicationStatus.project]: {
    bgColor: '#FEF5DA',
    textColor: '#725701'
  },
  [LeaveApplicationStatus.managerApprovalWaiting]: {
    bgColor: '#CCF391',
    textColor: '#02622F'
  },
  [LeaveApplicationStatus.rework]: {
    bgColor: '#DEDEFF',
    textColor: '#5B5AFF'
  },
  [LeaveApplicationStatus.orderCreation]: {
    bgColor: '#CCF3DE',
    textColor: '#02622E'
  },
  [LeaveApplicationStatus.orderSigned]: {
    bgColor: '#90A4AE',
    textColor: '#FFFFFF'
  },
  [LeaveApplicationStatus.archive]: {
    bgColor: '#F0F0F0',
    textColor: '#333333'
  },
  [LeaveApplicationStatus.rejected]: {
    bgColor: '#F4D5DB',
    textColor: '#A2253F'
  },
  [LeaveApplicationStatus.atSigning]: {
    bgColor: '#EEE7D0',
    textColor: '#9A4600'
  },
  [LeaveApplicationStatus.frontOfficeApproval]: {
    bgColor: '#FFD54F',
    textColor: '#333333'
  },
  [LeaveApplicationStatus.backOfficeApproval]: {
    bgColor: '#A1887F',
    textColor: '#FFFFFF'
  },
  [LeaveApplicationStatus.orderSigning]: {
    bgColor: '#90CAF9',
    textColor: '#333333'
  },
  [LeaveApplicationStatus.familiarizationWithOrder]: {
    bgColor: '#E7F7D6',
    textColor: '#477B10'
  },
  [LeaveApplicationStatus.orderSentToUser]: {
    bgColor: '#9575CD',
    textColor: '#442a70'
  },
  [LeaveApplicationStatus.orderSignedAndInformed]: {
    bgColor: '#AAE7D0',
    textColor: '#02622E'
  },
  [LeaveApplicationStatus.pending]: {
    bgColor: '#f4a35f',
    textColor: '#6f581f'
  }
};
