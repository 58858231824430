/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Divider, Steps, type TabsProps } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import { ALLOWED_TO_EDIT_STATUSES, LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';
import {
  InfoForm,
  LeaveApplication,
  PartTimeJobForm,
  UploadDocumentsForm,
  useSignApplication,
  useStepperForm,
  useValidateApplicationStepper
} from 'src/features/leaveApplication';
import { History } from 'src/features/leaveApplication/components/History';
import { ApplicationsWarning, LeaveApplicationTabs } from 'src/features/leaveApplications';
import { useAppDispatch } from 'src/hooks';
import {
  cleanUpCurrentLeaveApplication,
  submitLeaveApplication,
  existentPartTimeJobsLeaveApplicationsSelector,
  currentLeaveApplicationPartTimeJobsIsLoadingSelector,
  currentLeaveApplicationSelector,
  deletePartTimeApplications,
  getLeaveApplicationDocuments,
  leaveApplicationItemsIsLoadingSelector,
  leaveApplicationTypesIsLoadingSelector,
  leaveApplicationTypesSelector,
  needMainLeaveApplicationUpdate,
  setSignDocumentsModalOpen,
  getLeaveApplication,
  getLeaveApplicationTypesList,
  getLeaveApplicationsList,
  getLeaveApplicationHistory,
  partTimeJobsItemsSelector,
  clearCurrentLeaveApplicationDocuments,
  clearPartTimeJobLeaveApplications,
  needToRemovePartTimeJobLeaveApplications,
  getAllFullPartTimeLeaveApplications,
  getPartTimeJobs,
  validateLeaveApplicationDates,
  setIsValidationLoading,
  reserveVacationSummarySelector
} from 'src/store/leaveApplications';
import {
  getPartTimeJobProfiles,
  partTimeJobProfilesSelector,
  profileSelector
} from 'src/store/sessions';
import {
  STEPPER_MODE,
  type ILeaveApplicationFormData
} from 'src/types/leaveApplications/types';
import { type IProfile } from 'src/types/user/types';
import { momentDate } from 'src/utils/date';
import { ROUTES } from 'src/constants/routes';
import { STEP_NUMBER_BY_NAME } from 'src/features/leaveApplication/hooks/useStepperForm';
import { Typography } from 'antd/lib';
import { partTimeJobsValidator, prepareDocumentsValidator, prepareInfoValidator } from 'src/features/leaveApplication/utils/stepValidators';

const Section = styled.section`
  padding: 0 28px;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${(props) => props.theme.antd.colorWhite};
  }
`;

const { Container, Content, Footer } = LeaveApplication;

function prepareFooterButtonLabels(
  currentStep: number,
  isLastStep: boolean,
  leaveApplicationStatus: LeaveApplicationStatus
) {
  let okLabel = 'Продовжити';
  let prevLabel = 'Назад';
  let cancelLabel = 'Скасувати';

  if (isLastStep) {
    okLabel = 'Підписати';
  }

  if (leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder) {
    cancelLabel = 'Повернутись до списку заявок';
  }

  if (currentStep === 0) {
    prevLabel = 'Скасувати';

    if (leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder) {
      prevLabel = 'Повернутись до списку заявок';
    }
  }

  return {
    okLabel,
    prevLabel,
    cancelLabel
  };
}

export const EditLeave: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const vacationId = params.id;

  const profile = useSelector(profileSelector) as IProfile;
  const partTimeJobProfiles = useSelector(partTimeJobProfilesSelector);
  const existentPartTimeJobsLeaveApplications = useSelector(existentPartTimeJobsLeaveApplicationsSelector);
  const currentLeaveApplication = useSelector(currentLeaveApplicationSelector);
  const partTimeJobsIsLoading = useSelector(currentLeaveApplicationPartTimeJobsIsLoadingSelector);
  const leaveApplicationTypes = useSelector(leaveApplicationTypesSelector);
  const reserveVacationSummary = useSelector(reserveVacationSummarySelector);
  const leaveApplicationTypesIsLoading = useSelector(leaveApplicationTypesIsLoadingSelector);
  const leaveApplicationItemsIsLoading = useSelector(leaveApplicationItemsIsLoadingSelector);
  const partTimeJobsItems = useSelector(partTimeJobsItemsSelector);

  const leaveApplicationStatus = LeaveApplicationStatusId[currentLeaveApplication.data?.statusId || 0];
  const isCompanyInfoDisabled = leaveApplicationStatus === LeaveApplicationStatus.project || leaveApplicationStatus === LeaveApplicationStatus.rework;
  const formDisabled = leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder;
  const tabLabel = leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder ? 'Підписання наказу' : 'Редагування заявки';

  const { formData, errors, resetStepErrors, changeStepErrors, changeFormData } =
    useStepperForm({
      profile,
      mode: STEPPER_MODE.EDIT,
      partTimeJobs: partTimeJobsItems,
      leaveApplication: currentLeaveApplication.data,
      leaveApplicationDocuments: currentLeaveApplication.previewDocuments,
      leaveApplicationTypes
    });

  const isPartTimeJobSelected = useMemo(() => {
    const [, ...onlyPartTimeJobProfiles] = partTimeJobProfiles;

    return onlyPartTimeJobProfiles?.some(
      (profile) => profile.positionIdentifier === formData.info.positionIdentifier
    );
  }, [partTimeJobProfiles, formData.info.positionIdentifier]);

  const showPartTimeJobsStep = partTimeJobProfiles.length > 1 && !isPartTimeJobSelected;

  useSignApplication({
    formData,
    currentLeaveApplication
  });

  const removePartTimeJobs = useCallback(async () => {
    if (
      currentLeaveApplication.data &&
      currentLeaveApplication.needToRemovePartTimeJobLeaveApplications &&
      existentPartTimeJobsLeaveApplications.length
    ) {
      const ids = existentPartTimeJobsLeaveApplications.map(i => i.vacationId);

      await dispatch(deletePartTimeApplications({ ids }));
      dispatch(clearCurrentLeaveApplicationDocuments());
      dispatch(clearPartTimeJobLeaveApplications(currentLeaveApplication.data.vacationId));
      await dispatch(getLeaveApplicationDocuments(currentLeaveApplication.data.vacationId))
    }
  }, [
    currentLeaveApplication.data,
    currentLeaveApplication.needToRemovePartTimeJobLeaveApplications,
    existentPartTimeJobsLeaveApplications,
    dispatch
  ]);

  const validateApplicationDates = useCallback(async () => {
    dispatch(setIsValidationLoading(true));
    await removePartTimeJobs();
    await dispatch(validateLeaveApplicationDates(formData)).unwrap();
  }, [formData, removePartTimeJobs, dispatch])

  const handleSubmit = (data: ILeaveApplicationFormData) => {
    if (LeaveApplicationStatusId[data.info.statusId] === LeaveApplicationStatus.familiarizationWithOrder) {
      dispatch(setSignDocumentsModalOpen(true));
    } else {
      dispatch(submitLeaveApplication(data));
    }
  };

  const handleChange = (step: keyof ILeaveApplicationFormData) => (name: string, option: any) => {
    const needToRemovePartTimeJobApplications =
      (name === 'vacationType' && formData.info.vacationType !== option.value) ||
      (name === 'dateFrom' && formData.info.dateFrom && !formData.info.dateFrom.isSame(option, 'day')) ||
      (name === 'dateTo' && formData.info.dateTo && !formData.info.dateTo.isSame(option, 'day'));

    dispatch(needToRemovePartTimeJobLeaveApplications(needToRemovePartTimeJobApplications));

    const { data } = currentLeaveApplication;
    const needMainApplicationUpdate =
      (name === 'vacationType' && data?.vacationIdentifier !== option.value) ||
      (name === 'dateFrom' && data?.dateFrom && !momentDate(data?.dateFrom).isSame(option, 'day')) ||
      (name === 'dateTo' && data?.dateTo && !momentDate(data?.dateTo).isSame(option, 'day')) ||
      (name === 'addToNextSalary' && data?.addToNextSalary !== option) ||
      (name === 'comment' && data?.comment !== option);

    dispatch(needMainLeaveApplicationUpdate(needMainApplicationUpdate));

    changeFormData(step)(name, option);
  }

  const needLeaveApplicationValidation = currentLeaveApplication.needToRemovePartTimeJobLeaveApplications;

  const { currentStep, currentStepErrors, isLastStep, handleCancel, handleNext, handlePrev } =
    useValidateApplicationStepper({
      formData,
      errors,
      showPartTimeJobsStep,
      mode: STEPPER_MODE.EDIT,
      resetStepErrors,
      onSubmit: handleSubmit,
      stepValidators: [
        prepareInfoValidator(formData, needLeaveApplicationValidation, changeStepErrors, validateApplicationDates),
        prepareDocumentsValidator(formData, leaveApplicationTypes, changeStepErrors),
        partTimeJobsValidator
      ]
    });

  useEffect(() => {
    const getAllData = async () => {
      if (vacationId) {
        try {
          const id = Number(vacationId);
          const { leaveApplication } = await dispatch(getLeaveApplication(id)).unwrap();

          if (!ALLOWED_TO_EDIT_STATUSES.includes(LeaveApplicationStatusId[leaveApplication.statusId])) {
            throw new Error('Forbidden');
          }

          await dispatch(getPartTimeJobProfiles());
          await dispatch(getLeaveApplicationTypesList());
          await dispatch(getLeaveApplicationsList());
          await dispatch(getLeaveApplicationDocuments(id));
          await dispatch(getLeaveApplicationHistory(id));
        } catch (e) {
          navigate(ROUTES.LEAVE_APPLICATIONS);
        }
      }
    };

    getAllData();

    return () => {
      dispatch(cleanUpCurrentLeaveApplication());
    };
  }, [dispatch, vacationId]);

  useEffect(() => {
    if (currentStep === STEP_NUMBER_BY_NAME.partTimeJobs) {
      if (
        (
          LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.project ||
          LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.familiarizationWithOrder ||
          LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.rework
        ) &&
        existentPartTimeJobsLeaveApplications?.length &&
        !currentLeaveApplication.needToRemovePartTimeJobLeaveApplications
      ) {
        dispatch(getAllFullPartTimeLeaveApplications(existentPartTimeJobsLeaveApplications.map(i => i.vacationId)));
      }

      if (
        (
          LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.rework &&
          currentLeaveApplication.needToRemovePartTimeJobLeaveApplications
        ) ||
        currentLeaveApplication.needToRemovePartTimeJobLeaveApplications ||
        LeaveApplicationStatusId[formData.info.statusId] === LeaveApplicationStatus.default
      ) {
        dispatch(getPartTimeJobs(formData));
        dispatch(needToRemovePartTimeJobLeaveApplications(false));
      }
    }
  }, [currentStep]);

  const isLoading =
    currentLeaveApplication.isLoading ||
    partTimeJobsIsLoading ||
    leaveApplicationTypesIsLoading ||
    leaveApplicationItemsIsLoading ||
    !currentLeaveApplication.data;

  const getSteps = () => {
    const defaultSteps = [
      {
        title: 'Інформація про відпустку',
        content: (
          <InfoForm
            data={formData.info}
            errors={currentStepErrors}
            partTimeJobProfiles={partTimeJobProfiles}
            leaveApplicationTypes={leaveApplicationTypes}
            reserveVacationSummary={reserveVacationSummary}
            leaveApplicationTypesIsLoading={leaveApplicationTypesIsLoading}
            onChange={handleChange('info')}
            isCompanyInfoDisabled={isCompanyInfoDisabled}
            disabled={formDisabled}
            isLoading={isLoading}
          />
        )
      },
      {
        title: 'Документи',
        content: (
          <UploadDocumentsForm
            documents={formData.documents}
            comment={formData.info.comment}
            errors={currentStepErrors}
            onChange={changeFormData('documents')}
            onChangeComment={handleChange('info')}
            disabled={formDisabled}
            leaveApplicationStatus={leaveApplicationStatus}
          />
        )
      }
    ];

    if (showPartTimeJobsStep) {
      defaultSteps.push({
        title: 'Інформація про сумісництво',
        content: (
          <PartTimeJobForm
            data={formData}
            existentPartTimeJobsLeaveApplications={existentPartTimeJobsLeaveApplications}
            onChange={changeFormData('partTimeJobs')}
            isLoading={isLoading}
            partTimeJobsIsLoading={partTimeJobsIsLoading}
            disabled={formDisabled}
          />
        )
      });
    }

    return defaultSteps;
  };

  const steps = getSteps();
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const StepContent = steps[currentStep].content;
  const { okLabel, prevLabel, cancelLabel } = prepareFooterButtonLabels(
    currentStep,
    isLastStep,
    leaveApplicationStatus
  );

  const tabs: TabsProps['items'] = [
    {
      key: 'leaveApplication',
      label: !isLoading && tabLabel,
      children: (
        <>
          <Content>
            <Section style={{ margin: '14px 0 28px 0px' }}>
              {currentLeaveApplication.data?.statusId &&
                LeaveApplicationStatusId[currentLeaveApplication.data?.statusId] === LeaveApplicationStatus.rework
                ? <ApplicationsWarning>
                    Звертаємо Вашу увагу на необхідні зміни у Вашій історії
                  </ApplicationsWarning>
                : null}
            </Section>
            <Section style={{ margin: '14px 0 28px 0px' }}>
              <Typography.Title level={5}>
                Заявка № {currentLeaveApplication.data?.vacationId}
              </Typography.Title>
            </Section>
            <Section style={{ margin: '14px 0 28px 0px' }}>
              <StyledSteps current={currentStep} items={items} />
            </Section>
            {StepContent}
          </Content>
          <Divider />
          <Footer
            okText={okLabel}
            prevText={prevLabel}
            cancelText={cancelLabel}
            onOk={debounce(handleNext, 500)}
            onPrev={debounce(handlePrev, 500)}
            onCancel={currentStep !== 0 ? handleCancel : undefined}
            disabled={isLoading || currentLeaveApplication.isValidationLoading}
          />
        </>
      )
    },
    {
      key: 'history',
      label: !isLoading && 'Історія',
      children: <History data={currentLeaveApplication.history} />
    }
  ];

  return (
    <Container>
      <LeaveApplicationTabs tabs={tabs} defaultKey="leaveApplication" />
    </Container>
  );
};
