import { type LeaveApplicationStatus } from 'src/constants/status';
import { type RangeValue } from '../common';
import { type Moment } from 'moment';
import { type UploadFile } from 'antd';
import { type DefaultOptionType } from 'antd/es/select';

export interface ILeaveApplicationEntity {
  vacationId: number;
  vacationIdentifier: string;
  key: string;
  dataIndex: string;
  vacationName: string;
  dateFrom: string;
  dateTo: string;
  daysLimit: number;
  status: LeaveApplicationStatus;
  statusId: number;
  companyIdentifier: string;
  positionIdentifier: string;
  companyName: string;
  departmentName: string;
  positionName: string;
  admManager: string;
  fnManager: string;
  admManagerPib?: string;
  fnManagerPib?: string;
  usedDays: number;
  principalName: string;
  parentRequestID_FK?: number;
  addToNextSalary: boolean;
  comment?: string;
  document: UploadFile;
}

export interface ICreateLeavePayload {
  originatorPrincipal: string;
  positionIdentifier: string;
  vacationIdentifier: string;
  companyIdentifier: string;
  dateFrom: string;
  dateTo: string;
  addToNextSalary: boolean;
  parentRequestID_FK?: number | null;
  admManager: string;
  fnManager: string | null;
  comment?: string;
}

export interface IUpdateLeavePayload {
  vacationId: number;
  vacationIdentifier: string;
  dateFrom: string;
  dateTo: string;
  addToNextSalary: boolean;
  comment?: string;
}
export interface IPartTimeJobLeaveApplicationEntity {
  key: string;
  dataIndex: string;
  organization: string;
  position: string;
  type: string;
  days: number;
  dateRange: string;
  functionalManager: string;
  adminManager: string;
  disabled?: boolean;
}

export interface ILeaveApplicationFilter {
  range: RangeValue<Moment>;
  statusName: LeaveApplicationStatus;
}

export interface ILeaveApplicationFormData {
  info: {
    pib: string;
    principalName: string;
    position: string;
    positionIdentifier: string;
    company: DefaultOptionType;
    department: DefaultOptionType;
    admManager: DefaultOptionType;
    fnManager: DefaultOptionType;
    vacationType: DefaultOptionType;
    daysLimit: number;
    dateFrom: Moment;
    dateTo: Moment;
    addToNextSalary: boolean;
    leaveApplicationTypes?: ILeaveApplicationType;
    vacationId?: number;
    fileContent?: string;
    days: number;
    comment?: string;
    statusId: number;
  };
  documents: {
    fileList: IPreviewDocument[];
  };
  partTimeJobs: { items: IPartTimeJobEntityExtended[] };
}

export type SignLeaveApplicationRecord = Pick<
ILeaveApplicationEntity,
| 'vacationName'
| 'dateFrom'
| 'dateTo'
| 'usedDays'
| 'positionName'
| 'document'
>;

export type IValidateLeavePayload =
  { vacationId?: number } &
  Pick<
  ILeaveApplicationEntity,
  | 'companyIdentifier'
  | 'dateFrom'
  | 'dateTo'
  | 'daysLimit'
  | 'vacationIdentifier'
  | 'positionIdentifier'
  | 'fnManager'
  | 'admManager'>;

export interface ILeaveApplicationType {
  vacationIdentifier: string;
  vacationName: string;
  daysLimit: number;
  fileRequired: boolean;
  fileRequiredMessage: string;
  vacationGroupType: number;
}

export interface ILeaveApplicationPagination {
  pageSize: number;
  currentPage: number;
  totalCount?: number;
}

export interface Filters {
  dateFrom?: string | null;
  dateTo?: string | null;
  status?: LeaveApplicationStatus | null;
}

export interface IDocumentUploadPayload {
  vacationId: number;
  filePath: string;
  title: string;
  content: string;
  comment: string;
}

export interface IUpdatedDocumentPayload {
  vacationId: number;
  filePath: string;
  fileContent: string;
}

export interface IApplicationDocumentData {
  id: number;
  name: string;
  title: string;
  content: string;
  filePath: string;
}
export interface ISignApplicationDocument {
  vacationId: number;
  document: IApplicationDocumentData;
}

export interface IPreviewDocument {
  vacationId: number;
  document: UploadFile;
}

export interface IPartTimeJobEntity {
  id: number;
  principalName_FK: string;
  company: string;
  companyIdentifier: string;
  positionIdentifier: string;
  department: string;
  position: string;
  admManager?: string;
  admManagerPrincipalName?: string;
  fnManager?: string;
  fnManagerPrincipalName?: string;
  daysLimit: number;
  usedDays: number;
  dateFrom: string;
  dateTo: string;
  vacationTypeId: string;
  vacationType: string;
  vacationIdentifier: string;
  vacationId?: number;
}

export interface IPartTimeJobEntityExtended extends IPartTimeJobEntity {
  isActive: boolean;
  disabled: boolean;
}

export interface ISubmitApplicationProcessPayload {
  id: number;
  processName: string;
}

export interface ISubmitApplicationActionPayload {
  id: number;
  stage: number;
}

export enum STEPPER_MODE {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view'
}
