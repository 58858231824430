import React from 'react';
import styled from 'styled-components';

import locale from 'antd/es/date-picker/locale/uk_UA';
import { Typography, Flex, DatePicker as DatePickerAnt } from 'antd';

import type { Moment } from 'moment';
import 'moment/locale/uk';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { type PickerProps } from 'antd/es/date-picker/generatePicker';

const MyDatePicker = DatePickerAnt.generatePicker<Moment>(momentGenerateConfig);

const { Text } = Typography;

const Container = styled(Flex)<{ isHeight?: boolean }>`
  height: ${(props) => props.isHeight ? '72px' : ''};
  width: 100%;
  justify-content: space-between;
`;

const StyledMyDatePicker = styled(MyDatePicker).attrs<{ 'data-testid': string, testId: string }>((props) => ({
  'data-testid': props.testId
}))<{ rounded?: boolean }>`
  border-radius: ${props => props.rounded ? '20px' : '8px'};
`;

interface IProps {
  title?: string;
  placeholder?: string;
  value?: PickerProps<Moment>['value'];
  allowClear?: PickerProps<Moment>['allowClear'];
  disabledDate?: PickerProps<Moment>['disabledDate']
  onChange?: PickerProps<Moment>['onChange'];
  disabled?: boolean;
  rounded?: boolean;
  format?: string;
  testId?: string;
  inputReadOnly?: boolean;
}

export const DatePicker: React.FC<IProps> = ({
  title,
  placeholder,
  value,
  allowClear,
  disabledDate,
  onChange,
  disabled,
  rounded,
  format,
  testId,
  inputReadOnly
}) => {
  return (
    <Container vertical isHeight={Boolean(title)}>
      <Text type='secondary' ellipsis>{title}</Text>
      <StyledMyDatePicker
        // @ts-expect-error // ignore because of styled component
        testId={testId}
        locale={locale}
        size='large'
        value={value}
        allowClear={allowClear}
        disabledDate={disabledDate}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        rounded={rounded}
        format={format}
        inputReadOnly={window.Cypress ? false : inputReadOnly}
      />
    </Container>
  );
};
