import { type DefaultOptionType } from 'antd/es/select';
import { type ILeaveApplicationType } from 'src/types/leaveApplications/types';

export type ApplicationTypeOption = {
  key: string;
  daysLimit: number;
  vacationGroupType: number;
} & DefaultOptionType;

export const dumpApplicationTypes = (data: ILeaveApplicationType[]): ApplicationTypeOption[] => {
  return data
    .map((item) => ({
      key: item.vacationIdentifier,
      label: item.vacationName,
      value: item.vacationIdentifier,
      daysLimit: item.daysLimit,
      vacationGroupType: item.vacationGroupType
    }));
};
