
import { ReactComponent as AlertIcon } from 'src/assets/images/alert.svg';

import React from 'react';
import styled from 'styled-components';
import { Flex, Typography } from 'antd';

const TextStyled = styled(Typography.Text)`
  color: ${(props) => props.theme.colors.danger};
`;

const AlertIconStyled = styled(AlertIcon)`
  margin-right: 5px;
  position: relative;
  top: 3px;
`;

interface IProps {
  children: React.ReactNode;
}

export const ErrorText: React.FC<IProps> = ({ children }) => {
  return (
    <Flex gap={4} align="center">
      <TextStyled>
        <AlertIconStyled />
        {children}
      </TextStyled>
    </Flex>
  );
};
