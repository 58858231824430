import React from 'react';
import styled from 'styled-components';
import { Typography, Flex, Collapse, Divider } from 'antd';
import { type DefaultOptionType } from 'antd/es/select';

import { ReactComponent as ExtendIcon } from 'src/assets/images/extend.svg';
import { VacationTypeOption } from './VacationTypeOption';

const { Text } = Typography;

const GroupItemContainer = styled(Flex)`
  max-height: 240px;
  overflow: scroll;
`;

export type DropdownGroupItem = DefaultOptionType;

export type DropdownGroup<T> = { title: string; items: T[] };

interface IProps<T> {
  menu: React.ReactElement,
  groups: Array<DropdownGroup<T & DropdownGroupItem>>,
  value: DropdownGroupItem,
  onChange: (option: DropdownGroupItem) => void;
}

export function DropdownGroupRender<T>({
  menu,
  groups,
  value,
  onChange
}: IProps<T>) {
  const handleOnClickCollapseItem = (option: DropdownGroupItem) => () => {
    onChange({
      label: option.label,
      value: option.value
    });
  };

  const items = groups.map((group) => {
    return {
      key: group.title,
      label: (
          <div data-testid={group.title} style={{ display: 'flex' }}>
            <div>
              <ExtendIcon style={{ margin: '0 5px 0 0' }} />
            </div>
            <Text>{group.title}</Text>
          </div>
      ),
      children: (
        <GroupItemContainer vertical>
          {group.items.map((item) => {
            const isSelected = value && value.value === item.value;

            return (
              <VacationTypeOption
                key={item.key}
                label={item.label}
                daysLimit={item.daysLimit}
                isSelected={isSelected}
                onClick={handleOnClickCollapseItem(item)}
                isPadding
                isHover
              />
            )
          })}
        </GroupItemContainer>
      )
    };
  });

  return (
    <div>
      {menu}
      {items.length ? <Divider style={{ margin: '3px 0' }} /> : null}
      <Collapse
        style={{ padding: 0 }}
        items={items}
        expandIconPosition='end'
        size='small'
        accordion
        ghost
      />
    </div>
  );
}
