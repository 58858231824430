import { LEAVE_APPLICATION_NAME, ORDER_NAME_SUFFIX } from 'src/constants/documentNames';
import { ERROR_TEXTS } from 'src/constants/errors/validation';
import { LeaveApplicationStatus, LeaveApplicationStatusId } from 'src/constants/status';
import type { ILeaveApplicationFormData, ILeaveApplicationType } from 'src/types/leaveApplications/types';

const INFO_STEP = 0;
const DOCUMENTS_STEP = 1;
const SYSTEM_DOCS = [LEAVE_APPLICATION_NAME, ORDER_NAME_SUFFIX];

export const prepareInfoValidator = (
  formData: ILeaveApplicationFormData,
  needLeaveApplicationValidation: boolean,
  changeStepErrors: (step: number, field: string, errorType: string) => void,
  validate: () => Promise<void>
) => async () => {
  const leaveApplicationStatus = LeaveApplicationStatusId[formData.info.statusId];

  if (leaveApplicationStatus === LeaveApplicationStatus.familiarizationWithOrder) {
    return true;
  }

  if (!formData.info.vacationType.value) {
    changeStepErrors(INFO_STEP, 'vacationType', ERROR_TEXTS.REQUIRED);
    return false;
  }

  if (
    needLeaveApplicationValidation ||
    (leaveApplicationStatus === LeaveApplicationStatus.default)
  ) {
    try {
      await validate();
    } catch (e) {
      return false;
    }
  }

  return true;
}

export const prepareDocumentsValidator = (
  formData: ILeaveApplicationFormData,
  leaveApplicationTypes: ILeaveApplicationType[],
  changeStepErrors: (step: number, field: string, errorType: string) => void
) => async () => {
  const additionalDocs = formData.documents.fileList.filter((item) => {
    return !SYSTEM_DOCS.some((name) => item.document.name.includes(name));
  });

  const selectedVacationType = leaveApplicationTypes.find(item => item.vacationIdentifier === formData.info.vacationType.value);

  if (
    selectedVacationType?.fileRequired &&
    !additionalDocs.length
  ) {
    changeStepErrors(DOCUMENTS_STEP, 'fileList', selectedVacationType.fileRequiredMessage);
    return false;
  }

  return true;
}

export const partTimeJobsValidator = async () => {
  return true;
}
