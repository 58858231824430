import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

const TextContainer = styled.div<{ isSelected?: boolean, isPadding?: boolean, isHover?: boolean }>`
  display: flex;
  padding: ${(props) => props.isPadding ? '5px 12px' : ''};
  font-weight: ${(props) => props.isSelected ? 'bold' : ''};
  background-color: ${(props) => props.isSelected ? props.theme.colors.borderColor : ''};
  border-radius: 4px;

  &:hover {
    background-color: ${(props) => !props.isSelected && props.isHover ? props.theme.colors.selectHover : ''};
  }
`;

const Label = styled.div`
  margin: 3px 0;
  padding: 6px 3px;
  border-radius: 3px;
  overflow: hidden;
  height: 54px;
  width: 95%;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DaysLimit = styled(Text)`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  label: React.ReactNode;
  daysLimit: number;
  isSelected?: boolean;
  isPadding?: boolean;
  isHover?: boolean;
  onClick?: () => void;
}

export function VacationTypeOption({
  label,
  daysLimit,
  isSelected,
  isPadding,
  isHover,
  onClick
}: IProps) {
  return (
    <TextContainer
      data-testid={label}
      isSelected={isSelected}
      isPadding={isPadding}
      isHover={isHover}
      onClick={onClick}
    >
      <Label>{label}</Label>
      <DaysLimit>{daysLimit}</DaysLimit>
    </TextContainer>
  );
}
