/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Checkbox, Flex, Grid, Spin, Typography } from 'antd';
import React, { useMemo, useRef } from 'react';
import moment, { type Moment } from 'moment';
import styled from 'styled-components';
import { type CheckboxChangeEvent } from 'antd/es/checkbox';
import { type PickerProps } from 'antd/es/date-picker/generatePicker';
import { type SelectProps } from 'antd/lib';
import { type DefaultOptionType } from 'antd/es/select';

import api from 'src/app/apiSingleton';
import { Col, DebounceSelect, Row } from 'src/components';
import { DatePicker } from 'src/components/DatePicker/DatePicker';
import { InfoField } from 'src/components/InfoField/InfoField';
import { Select } from 'src/components/Select/Select';
import {
  type ILeaveApplicationFormData,
  type ILeaveApplicationType
} from 'src/types/leaveApplications/types';
import { type IProfile } from 'src/types/user/types';
// import { dumpApplicationCompanyOptions } from 'src/utils/dumps/dumpApplicationCompanyOptions';
import { type ApplicationTypeOption, dumpApplicationTypes } from 'src/utils/dumps/dumpApplicationTypes';
import { type IValidationError } from 'src/types/common';
import { getLeaveApplicationDateDiff } from 'src/utils/date';
import { VACATION_GROUP_TYPES, VACATION_GROUP_TYPES_NUMBERS } from 'src/constants/vacationTypes';
import {
  type DropdownGroup,
  type DropdownGroupItem,
  DropdownGroupRender
} from 'src/components/Select/DropdownGroupRender';
import { VacationTypeOption } from 'src/components/Select/VacationTypeOption';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const Section = styled.section`
  padding: 0 28px;
`;

const FullWidthSection = styled.section`
  background: #f5f7fc;
  margin: 24px 0 0 0;
  padding: 28px;
`;

const LeaveDaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PRINCIPAL_NAME_PREFIX = 'MHP';

const managerFilter = (options: DefaultOptionType[]) => {
  return options.filter((item) => String(item.value).includes(PRINCIPAL_NAME_PREFIX));
}

interface IProps {
  data: ILeaveApplicationFormData['info'];
  errors: IValidationError;
  leaveApplicationTypes: ILeaveApplicationType[];
  reserveVacationSummary: number | null;
  onChange: (name: string, value: unknown) => void;
  partTimeJobProfiles: IProfile[];
  leaveApplicationTypesIsLoading?: boolean;
  isCompanyInfoDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

function groupVacationTypes(vacationTypes: ApplicationTypeOption[]): Record<number, DropdownGroup<ApplicationTypeOption>> {
  const grouped: Record<number, { title: string; items: ApplicationTypeOption[] }> = {};

  vacationTypes.forEach((item) => {
    const groupType = item.vacationGroupType;

    if (!grouped[groupType]) {
      grouped[groupType] = {
        title: VACATION_GROUP_TYPES[groupType],
        items: []
      };
    }

    grouped[groupType].items.push(item);
  });

  return grouped;
}

export const InfoForm: React.FC<IProps> = ({
  data,
  errors,
  onChange,
  partTimeJobProfiles,
  leaveApplicationTypes,
  reserveVacationSummary,
  disabled,
  isLoading,
  leaveApplicationTypesIsLoading,
  isCompanyInfoDisabled
}) => {
  const screen = useBreakpoint();
  const selectRef = useRef<any>();
  const currentVacationDays = getLeaveApplicationDateDiff(data.dateFrom, data.dateTo);

  const leaveTypeOptions = useMemo(() => {
    const newOptions = dumpApplicationTypes(leaveApplicationTypes);

    return newOptions;
  }, [leaveApplicationTypes]);

  const leaveTypesGroupedOptions = groupVacationTypes(leaveTypeOptions);

  const defaultLeaveTypeOptions = leaveTypesGroupedOptions[VACATION_GROUP_TYPES_NUMBERS.FAVORITE];
  const otherLeaveTypeGroups = [
    leaveTypesGroupedOptions[VACATION_GROUP_TYPES_NUMBERS.SOCIAL],
    leaveTypesGroupedOptions[VACATION_GROUP_TYPES_NUMBERS.WITHOUT_SALARY]
  ].filter(i => i);

  // const companyOptions = useMemo(
  //   () => dumpApplicationCompanyOptions(partTimeJobProfiles),
  //   [partTimeJobProfiles]
  // );

  const handleChangeDateField: (name: string) => PickerProps<Moment>['onChange'] =
    (name) => (date) => {
      if (name === 'dateFrom' && data.dateTo.diff(date) < 0) onChange('dateTo', date);

      onChange(name, date);
    };

  const toggleAddToNextSalary = (e: CheckboxChangeEvent) => {
    onChange('addToNextSalary', e.target.checked);
  };

  const handleSelect = (name: string, option: { value?: unknown }) => {
    if (name === 'vacationType') {
      const options = dumpApplicationTypes(leaveApplicationTypes);
      const selectedOption = options.find(item => item.value === option.value);

      onChange('daysLimit', selectedOption?.daysLimit);
    }

    if (name === 'company') {
      const partTimeJobProfile = partTimeJobProfiles.find(
        (profile) => profile.companyIdentifier === option.value
      );

      if (partTimeJobProfile) {
        onChange('department', { value: partTimeJobProfile.department, label: partTimeJobProfile.department });
        onChange('position', partTimeJobProfile.position);
        onChange('positionIdentifier', partTimeJobProfile.positionIdentifier);
      }
    }

    onChange(name, option);
  }

  const handleChangeSelect: (name: string) => SelectProps['onChange'] = (name) => (option) => {
    handleSelect(name, option);
  };

  const handleChangeSelectGroup: (name: string) => (option: DropdownGroupItem) => void = (name) => (option) => {
    handleSelect(name, option);
    setTimeout(() => selectRef.current.blur(), 0);
  };

  const disabledStartDate = (current: Moment) => {
    return current.isBefore(moment().subtract(1, 'days'));
  };

  const disabledEndDate = (current: Moment) => {
    return (current.isBefore(moment().subtract(1, 'days')) || current.isBefore(data.dateFrom));
  };

  if (isLoading) {
    return (
      <Flex align="center" justify="center" flex={1} style={{ minHeight: 300 }}>
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <>
      <Section>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12} lg={8}>
            <InfoField testId="user" title="Співробітник" text={data.pib} />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <InfoField testId="position" title="Посада" text={data.position} />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            {/* Replace InfoField with Select when approved by customer */}
            {/* <Select
              labelInValue
              testId="organization"
              title="Підприємство"
              value={data.company}
              onChange={handleChangeSelect('company')}
              options={companyOptions}
              disabled={isCompanyInfoDisabled || disabled}
            /> */}
            <InfoField
              testId="organization"
              title="Підприємство"
              text={data.company.label as string}
            />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            {/* Replace InfoField with Select when approved by customer */}
            {/* <Select
              testId="department"
              title="Підрозділ"
              value={data.department}
              onChange={handleChangeSelect('department')}
              options={[data.department]}
              disabled={isCompanyInfoDisabled || disabled}
            /> */}
            <InfoField
              testId="department"
              title="Підрозділ"
              text={data.department.label as string}
            />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <DebounceSelect
              testId="adminManager"
              title="Адміністративний керівник"
              value={data.admManager}
              fetcher={api.users.getList}
              getFetcherPayload={(value) => ({ search: value })}
              filter={managerFilter}
              onChange={handleChangeSelect('admManager')}
              disabled={isCompanyInfoDisabled || disabled}
            />
          </Col>
          <Col xs={24} sm={12} lg={8}>
            <DebounceSelect
              testId="functionalManager"
              title="Функціональний керівник"
              value={data.fnManager}
              fetcher={api.users.getList}
              getFetcherPayload={(value) => ({ search: value })}
              filter={managerFilter}
              onChange={handleChangeSelect('fnManager')}
              disabled={isCompanyInfoDisabled || disabled}
            />
          </Col>
        </Row>
      </Section>

      <FullWidthSection>
        <Title level={5} style={{ margin: 0, marginBottom: 24 }}>
          Інформація про відпустку
        </Title>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 1 }} lg={{ span: 8, order: 1 }}>
            <Select
              labelInValue
              testId="type"
              title="Вид відпустки"
              placeholder="Оберіть вид відпустки"
              loading={leaveApplicationTypesIsLoading}
              value={data.vacationType}
              onChange={handleChangeSelect('vacationType')}
              options={defaultLeaveTypeOptions?.items}
              disabled={disabled}
              errorText={errors?.vacationType}
              selectRef={selectRef}
              optionRender={(option) => (
                <VacationTypeOption label={option.label} daysLimit={option.data.daysLimit} />
              )}
              dropdownRender={(menu) => (
                <DropdownGroupRender
                  menu={menu}
                  value={data.vacationType}
                  groups={otherLeaveTypeGroups}
                  onChange={handleChangeSelectGroup('vacationType')}
                />
              )}
          />
          </Col>
          <Col xs={{ span: 24, order: 3 }} sm={{ span: 8, order: 4 }} lg={{ span: 8, order: 2 }}>
            <LeaveDaysWrapper>
              <Text type="secondary">Залишок обраної відпустки:</Text>
              <Text data-testid="remaining-days" style={{ marginTop: 20 }}>
                {data.daysLimit}
              </Text>
            </LeaveDaysWrapper>
          </Col>
          <Col xs={{ span: 24, order: 5 }} sm={{ span: 8, order: 5 }} lg={{ span: 8, order: 3 }}>
            <LeaveDaysWrapper>
              <Text type="secondary">Загальна кількість:</Text>
              <Text data-testid="days" style={{ marginTop: 20 }}>
                {reserveVacationSummary}
              </Text>
            </LeaveDaysWrapper>
          </Col>

          <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 2 }} lg={{ span: 8, order: 4 }}>
            <Flex vertical={screen.xs} justify="space-between" gap={10}>
              <DatePicker
                testId='dateFrom'
                title="Початок відпустки"
                value={data.dateFrom}
                allowClear={false}
                onChange={handleChangeDateField('dateFrom')}
                disabledDate={disabledStartDate}
                disabled={leaveApplicationTypesIsLoading || disabled}
                format='DD.MM.YYYY'
                inputReadOnly
              />
              <DatePicker
                testId='dateTo'
                title="Кінець відпустки"
                value={data.dateTo}
                allowClear={false}
                onChange={handleChangeDateField('dateTo')}
                disabledDate={disabledEndDate}
                disabled={leaveApplicationTypesIsLoading || disabled}
                format='DD.MM.YYYY'
                inputReadOnly
              />
            </Flex>
          </Col>
          <Col xs={{ span: 24, order: 4 }} sm={{ span: 8, order: 3 }} lg={{ span: 16, order: 5 }}>
            <LeaveDaysWrapper>
              <Text type="secondary">Кількість днів відпустки:</Text>
              <Text data-testid="days" style={{ marginTop: 20 }}>
                {currentVacationDays}
              </Text>
            </LeaveDaysWrapper>
          </Col>
        </Row>
      </FullWidthSection>

      <Section>
        <Checkbox
          checked={data.addToNextSalary}
          onChange={toggleAddToNextSalary}
          style={{ marginTop: 20 }}
          disabled={disabled}
        >
          Прошу оплату за дні відпустки виплатити у строк, встановлений для наступної виплати
          заробітної плати, але не пізніше найближчого платіжного дня підприємства
        </Checkbox>
      </Section>
    </>
  );
};
